//Install
import React, { Component } from "react";
//Install
import { ReactComponent as UJ1H06 } from "../../../Media/Icon/002.svg";
//Install
import { ReactComponent as T1968W } from "../../../Media/Icon/034.svg";
//Install
import { ReactComponent as Y8794E } from "../../../Media/Icon/035.svg";
//Install
import { ReactComponent as VP268H } from "../../../Media/Icon/036.svg";
//Install
import { ReactComponent as V38EA9 } from "../../../Media/Icon/037.svg";

//Element
class Information extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "menu", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 e74l2q" },
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center", title: "Telefon", href: "/" },
                    //Element
                    React.createElement( T1968W, {} ),
                    //Element
                    "+90 840 444 00 44"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center", title: "E-mail", href: "/" },
                    //Element
                    React.createElement( Y8794E, {} ),
                    //Element
                    "info@syzgrup.com"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center", title: "Adres", href: "/" },
                    //Element
                    React.createElement( VP268H, {} ),
                    //Element
                    "Collins Street 8007, USA"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center", title: "Çalışma Saatleri", href: "/" },
                    //Element
                    React.createElement( V38EA9, {} ),
                    //Element
                    "Mon - Sat : 8.00 - 19.00"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center", title: "İletişim Bilgileri", href: "/" },
                    //Element
                    "İletişim Bilgileri",
                    //Element
                    React.createElement( UJ1H06, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Information;