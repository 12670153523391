//Install
import React, { Component } from "react";
//Install
import { ReactComponent as H9CC19 } from "../../../../Media/Icon/002.svg";
//Install
import { ReactComponent as UQE562 } from "../../../../Media/Icon/003.svg";

//Element
class Document extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-4 e0068v" },
            //Element
            React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-4 text-center b2d607", href: "/" },
                //Element
                "Daha Fazla Yükle",
                //Element
                React.createElement( UQE562, {} )
            ),
            //Element
            React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-4 ms-4 text-center p1fn80", href: "/" },
                //Element
                "Tümünü Göster",
                //Element
                React.createElement( H9CC19, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Document;