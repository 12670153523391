//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as K34G92 } from "../../../Media/Icon/002.svg";
//Install
import Featured from "../../../Media/Image/006.png";

//Element
class Product extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-2 pe-3 m-0 bg-white position-absolute d-none k69jr3" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:12 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 ar92w4" },
                        //Element
                        React.createElement( "img", { alt: "e-Vadeli Hesap", title: "e-Vadeli Hesap", src: Featured, width: "71", height: "71" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:12 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 pt-3 pb-2 m-0 xiq771" },
                        //Element
                        React.createElement( "p", { className: "d-flex w-100 justify-content-center p-0 m-0 t95156" },
                            //Element
                            "Urunler",
                            //Element
                            React.createElement( "strong", { className: "float-start text-center position-absolute end-0 top-0" },
                                //Element
                                "3"
                            )
                        ),
                        //Element
                        React.createElement( "h6", { className: "d-flex w-100 justify-content-center p-0 m-0 j4108f" },
                            //Element
                            "e-Vadeli Hesap",
                            //Element
                            React.createElement( K34G92, {} )
                        ),
                        //Element
                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-center nj8064" },
                            //Element
                            "Paranızı Garanti BBVA değerlendirin."
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Product;