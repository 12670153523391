//Install
import React, { Component } from "react";
//Install
import { ReactComponent as W435KH } from "../../../../Media/Icon/042.svg";
//Install
import { ReactComponent as E2871U } from "../../../../Media/Icon/043.svg";
//Install
import { ReactComponent as L90YU5 } from "../../../../Media/Icon/044.svg";

//Element
class Filter extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-end w-auto p-0 m-0 e655mj" },
            //Element
            React.createElement( "button", { className: "float-start w-auto p-0 m-0 bg-transparent border-0 d567e4", type: "button" },
                //Element
                React.createElement( W435KH, {} )
            ),
            //Element
            React.createElement( "button", { className: "float-start w-auto p-0 m-0 ms-2 bg-transparent border-0 d567e4", type: "button" },
                //Element
                React.createElement( E2871U, {} )
            ),
            //Element
            React.createElement( "button", { className: "float-start w-auto p-0 m-0 ms-2 bg-transparent border-0 d567e4", type: "button" },
                //Element
                React.createElement( L90YU5, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Filter;