//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import { ReactComponent as Z92H5B } from "../../../Media/Icon/004.svg";
//Install
import { ReactComponent as I6672B } from "../../../Media/Icon/013.svg";
//Install
import { ReactComponent as XR278M } from "../../../Media/Icon/014.svg";
//Install
import { ReactComponent as O1J843 } from "../../../Media/Icon/015.svg";
//Install
import { ReactComponent as ME9946 } from "../../../Media/Icon/017.svg";
//Install
import { ReactComponent as K95U21 } from "../../../Media/Icon/018.svg";
//Install
import { ReactComponent as L8O0J7 } from "../../../Media/Icon/019.svg";
//Install
import { ReactComponent as K9SR58 } from "../../../Media/Icon/020.svg";
//Install
import { ReactComponent as A9428P } from "../../../Media/Icon/021.svg";
//Install
import One from "../../Shortcode/Slider/Menu/One";
//Install
import Two from "../../Shortcode/Slider/Menu/Two";
//Install
import List from "../../Shortcode/List/Default";
//Install
import Icon from "../../Shortcode/Navigation/Menu/Icon";
//Install
import Text from "../../Shortcode/Navigation/Menu/Text";
//Install
import Three from "../../Shortcode/Slider/Menu/Three";
//Install
import Banner from "../../Shortcode/Banner/Default";

//Element
class Menu extends Component {
    //Element
    OD4M51 = () => {
        //Element
        let ZOG419 = document.getElementsByClassName( "xl2233" )[0]
        //Element
        ZOG419.classList.add( "d-none" )
    }
    //Element
    render() {
        //Element
        const Z51KW7 = {
            //Element
            spaceBetween    : "12",
            //Element
            slidesPerView   : "1",
            //Element
            //autoplay        : {
                //Element
                //delay       : 5000
            //},
            //Element
            navigation      : {
                //Element
                nextEl      : ".kgt509",
                //Element
                prevEl      : ".d1m9j1"
            },
            //Element
            pagination      : {
                //Element
                el          : ".bcn638",
                //Element
                clickable   : true
            },
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const W628IP = {
            //Element
            spaceBetween    : "12",
            //Element
            slidesPerView   : "3",
            //Element
            //autoplay        : {
                //Element
                //delay       : 5000
            //},
            //Element
            navigation      : {
                //Element
                nextEl      : ".kgt509",
                //Element
                prevEl      : ".d1m9j1"
            },
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 3
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const A6588V = {
            //Element
            spaceBetween    : "12",
            //Element
            slidesPerView   : "3",
            //Element
            //autoplay        : {
                //Element
                //delay       : 5000
            //},
            //Element
            navigation      : {
                //Element
                nextEl      : ".kgt509",
                //Element
                prevEl      : ".d1m9j1"
            },
            //Element
            pagination      : {
                //Element
                el          : ".s119rc",
                //Element
                clickable   : true
            },
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 3
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const W0N1G7 = {
            //Element
            spaceBetween    : "12",
            //Element
            slidesPerView   : "3",
            //Element
            //autoplay        : {
                //Element
                //delay       : 5000
            //},
            //Element
            navigation      : {
                //Element
                nextEl      : ".kgt509",
                //Element
                prevEl      : ".d1m9j1"
            },
            //Element
            pagination      : {
                //Element
                el          : ".j7561b",
                //Element
                clickable   : true
            },
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 3
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const P309J9 = {
            //Element
            spaceBetween    : "12",
            //Element
            slidesPerView   : "3",
            //Element
            //autoplay        : {
                //Element
                //delay       : 5000
            //},
            //Element
            navigation      : {
                //Element
                nextEl      : ".kgt509",
                //Element
                prevEl      : ".d1m9j1"
            },
            //Element
            pagination      : {
                //Element
                el          : ".j7561b",
                //Element
                clickable   : true
            },
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 3
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "nav", { id: "Web_UR517X", className: "float-start w-100 p-0 m-0 iyi618" },
            //Element
            React.createElement( "menu", { className: "d-flex w-100 justify-content-start align-items-center p-0 ps-3 m-0 q618vm" },
                //Element
                React.createElement( "li", {},
                    //Element
                    React.createElement( "a", { className: "d-flex w-auto flex-column position-relative text-uppercase", title: "Ürünler", href: "/urunler" },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1" },
                            //Element
                            "Ürünler"
                        ),
                        //Element
                        React.createElement( "strong", { className: "float-start w-auto p-0 m-0 fw-normal" },
                            //Element
                            "Neler Yapıldı"
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-absolute d-none xl2233" },
                        //Element
                        React.createElement( Container, {},
                            //Element
                            React.createElement( Row, {},
                                //Element
                                React.createElement( Col, { md: "9 offset-md-1" },
                                    //Element
                                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 uy8e41" },
                                        //Element
                                        React.createElement( "li", {},
                                            //Element
                                            React.createElement( "a", { className: "float-start w-auto position-relative kh081o", title: "Bioklimatik Pergola", href: "/" },
                                                //Element
                                                "Bioklimatik Pergola"
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-absolute g26022" },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100" },
                                                    //Element
                                                    React.createElement( Container, {},
                                                        //Element
                                                        React.createElement( Row, {},
                                                            //Element
                                                            React.createElement( Col, { md: "8 offset-md-1" },
                                                                //Element
                                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 zq02e9" },
                                                                    //Element
                                                                    React.createElement( Icon, {} ),
                                                                    //Element
                                                                    React.createElement( Text, {} )
                                                                ),
                                                                //Element
                                                                React.createElement( "div", { id: "Web_H888VY", className: "float-start w-100 p-0 m-0 m0170q" },
                                                                    //Element
                                                                    React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...P309J9 },
                                                                        //Element
                                                                        React.createElement( SwiperSlide, {},
                                                                            //Element
                                                                            React.createElement( Three, { ID: "0", Title: "", Summary: "", Image: "", Target: "/" } )
                                                                        ),
                                                                        //Element
                                                                        React.createElement( SwiperSlide, {},
                                                                            //Element
                                                                            React.createElement( Three, { ID: "0", Title: "", Summary: "", Image: "", Target: "/" } )
                                                                        ),
                                                                        //Element
                                                                        React.createElement( SwiperSlide, {},
                                                                            //Element
                                                                            React.createElement( Three, { ID: "0", Title: "", Summary: "", Image: "", Target: "/" } )
                                                                        ),
                                                                        //Element
                                                                        React.createElement( SwiperSlide, {},
                                                                            //Element
                                                                            React.createElement( Three, { ID: "0", Title: "", Summary: "", Image: "", Target: "/" } )
                                                                        )
                                                                    ),
                                                                    //Element
                                                                    React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...W0N1G7 },
                                                                        //Element
                                                                        React.createElement( SwiperSlide, {},
                                                                            //Element
                                                                            React.createElement( Three, { ID: "0", Title: "", Summary: "", Image: "", Target: "/" } )
                                                                        ),
                                                                        //Element
                                                                        React.createElement( SwiperSlide, {},
                                                                            //Element
                                                                            React.createElement( Three, { ID: "0", Title: "", Summary: "", Image: "", Target: "/" } )
                                                                        ),
                                                                        //Element
                                                                        React.createElement( SwiperSlide, {},
                                                                            //Element
                                                                            React.createElement( Three, { ID: "0", Title: "", Summary: "", Image: "", Target: "/" } )
                                                                        ),
                                                                        //Element
                                                                        React.createElement( SwiperSlide, {},
                                                                            //Element
                                                                            React.createElement( Three, { ID: "0", Title: "", Summary: "", Image: "", Target: "/" } )
                                                                        )
                                                                    ),
                                                                    //Element
                                                                    React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 pt-3 m-0 j7561b" } )
                                                                ),
                                                                //Element
                                                                React.createElement( "div", { id: "Web_V6SV79", className: "float-start w-100 p-0 m-0 d-none m0170q" },
                                                                    //Element
                                                                    React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...A6588V },
                                                                        //Element
                                                                        React.createElement( SwiperSlide, {},
                                                                            //Element
                                                                            React.createElement( Two, { ID: "0", Title: "", Summary: "", Image: "", Target: "/" } )
                                                                        ),
                                                                        //Element
                                                                        React.createElement( SwiperSlide, {},
                                                                            //Element
                                                                            React.createElement( Two, { ID: "0", Title: "", Summary: "", Image: "", Target: "/" } )
                                                                        ),
                                                                        //Element
                                                                        React.createElement( SwiperSlide, {},
                                                                            //Element
                                                                            React.createElement( Two, { ID: "0", Title: "", Summary: "", Image: "", Target: "/" } )
                                                                        ),
                                                                        //Element
                                                                        React.createElement( SwiperSlide, {},
                                                                            //Element
                                                                            React.createElement( Two, { ID: "0", Title: "", Summary: "", Image: "", Target: "/" } )
                                                                        )
                                                                    ),
                                                                    //Element
                                                                    React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 pt-3 m-0 mb-3 s119rc" } )
                                                                ),
                                                                //Element
                                                                React.createElement( "div", { id: "Web_F99B61", className: "float-start w-100 p-0 m-0 mb-3 d-none m0170q" },
                                                                    //Element
                                                                    React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Z51KW7 },
                                                                        //Element
                                                                        React.createElement( SwiperSlide, {},
                                                                            //Element
                                                                            React.createElement( One, { ID: "0", Title: "", Summary: "", Image: "", Target: "/" } )
                                                                        ),
                                                                        //Element
                                                                        React.createElement( SwiperSlide, {},
                                                                            //Element
                                                                            React.createElement( One, { ID: "0", Title: "", Summary: "", Image: "", Target: "/" } )
                                                                        )
                                                                    ),
                                                                    //Element
                                                                    React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 pt-3 m-0 bcn638" } )
                                                                ),
                                                                //Element
                                                                React.createElement( "div", { id: "Web_S3V424", className: "float-start w-100 p-0 m-0 d-none m0170q" },
                                                                    //Element
                                                                    React.createElement( List, {} )
                                                                )
                                                            ),
                                                            //Element
                                                            React.createElement( Col, { md:3 },
                                                                //Element
                                                                React.createElement( Banner, {} )
                                                            ),
                                                            //Element
                                                            React.createElement( Col, { md: "8 offset-md-1" },
                                                                //Element
                                                                React.createElement( "div", { className: "float-start w-100 p-4 pt-4 m-0 s88371" },
                                                                    //Element
                                                                    React.createElement( Row, {},
                                                                        //Element
                                                                        React.createElement( Col, { md:4 },
                                                                            //Element
                                                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 i1056a" },
                                                                                //Element
                                                                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 fb181x" },
                                                                                    //Element
                                                                                    React.createElement( I6672B, {} ),
                                                                                    //Element
                                                                                    "Araçlar"
                                                                                ),
                                                                                //Element
                                                                                React.createElement( "ol", { className: "float-start w-100 p-0 m-0 mt-2 s6597x" },
                                                                                    //Element
                                                                                    React.createElement( "li", { className: "float-start w-100" },
                                                                                        //Element
                                                                                        React.createElement( "a", { className: "float-start w-100 p-0 m-0", title: "", href: "/" },
                                                                                            //Element
                                                                                            "Mevduat Faizi Hesaplama"
                                                                                        )
                                                                                    ),
                                                                                    //Element
                                                                                    React.createElement( "li", { className: "float-start w-100" },
                                                                                        //Element
                                                                                        React.createElement( "a", { className: "float-start w-100 p-0 m-0", title: "", href: "/" },
                                                                                            //Element
                                                                                            "Hesaplama Araçları"
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        ),
                                                                        //Element
                                                                        React.createElement( Col, { md:4 },
                                                                            //Element
                                                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 i1056a" },
                                                                                //Element
                                                                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 fb181x" },
                                                                                    //Element
                                                                                    React.createElement( XR278M, {} ),
                                                                                    //Element
                                                                                    "Güvenlik"
                                                                                ),
                                                                                //Element
                                                                                React.createElement( "ol", { className: "float-start w-100 p-0 m-0 mt-2 s6597x" },
                                                                                    //Element
                                                                                    React.createElement( "li", { className: "float-start w-100" },
                                                                                        //Element
                                                                                        React.createElement( "a", { className: "float-start w-100 p-0 m-0", title: "", href: "/" },
                                                                                            //Element
                                                                                            "Sizin İçin Sunduklarımız"
                                                                                        )
                                                                                    ),
                                                                                    //Element
                                                                                    React.createElement( "li", { className: "float-start w-100" },
                                                                                        //Element
                                                                                        React.createElement( "a", { className: "float-start w-100 p-0 m-0", title: "", href: "/" },
                                                                                            //Element
                                                                                            "Olası Tehlikeler"
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        ),
                                                                        //Element
                                                                        React.createElement( Col, { md:4 },
                                                                            //Element
                                                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 i1056a" },
                                                                                //Element
                                                                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 fb181x" },
                                                                                    //Element
                                                                                    React.createElement( O1J843, {} ),
                                                                                    //Element
                                                                                    "Dijital"
                                                                                ),
                                                                                //Element
                                                                                React.createElement( "ol", { className: "float-start w-100 p-0 m-0 mt-2 s6597x" },
                                                                                    //Element
                                                                                    React.createElement( "li", { className: "float-start w-100" },
                                                                                        //Element
                                                                                        React.createElement( "a", { className: "float-start w-100 p-0 m-0", title: "", href: "/" },
                                                                                            //Element
                                                                                            "Müşteri Ol"
                                                                                        )
                                                                                    ),
                                                                                    //Element
                                                                                    React.createElement( "li", { className: "float-start w-100" },
                                                                                        //Element
                                                                                        React.createElement( "a", { className: "float-start w-100 p-0 m-0", title: "", href: "/" },
                                                                                            //Element
                                                                                            "Parola Al"
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        ),
                                        //Element
                                        React.createElement( "li", {},
                                            //Element
                                            React.createElement( "a", { className: "float-start w-auto position-relative kh081o", title: "Giyotin Cam", href: "/" },
                                                //Element
                                                "Giyotin Cam"
                                            )
                                        ),
                                        //Element
                                        React.createElement( "li", {},
                                            //Element
                                            React.createElement( "a", { className: "float-start w-auto position-relative kh081o", title: "Rüzgarlık", href: "/" },
                                                //Element
                                                "Rüzgarlık"
                                            )
                                        ),
                                        //Element
                                        React.createElement( "li", {},
                                            //Element
                                            React.createElement( "a", { className: "float-start w-auto position-relative kh081o", title: "ZIP Perde", href: "/" },
                                                //Element
                                                "ZIP Perde"
                                            )
                                        ),
                                        //Element
                                        React.createElement( "li", {},
                                            //Element
                                            React.createElement( "a", { className: "float-start w-auto position-relative kh081o", title: "Kış Bahçeleri", href: "/" },
                                                //Element
                                                "Kış Bahçeleri"
                                            )
                                        ),
                                        //Element
                                        React.createElement( "li", {},
                                            //Element
                                            React.createElement( "a", { className: "float-start w-auto position-relative kh081o", title: "Veranda", href: "/" },
                                                //Element
                                                "Veranda"
                                            )
                                        ),
                                        //Element
                                        React.createElement( "li", {},
                                            //Element
                                            React.createElement( "a", { className: "float-start w-auto position-relative kh081o", title: "Tente", href: "/" },
                                                //Element
                                                "Tente"
                                            )
                                        ),
                                        //Element
                                        React.createElement( "li", {},
                                            //Element
                                            React.createElement( "a", { className: "float-start w-auto position-relative kh081o", title: "Tüm Ürün Grupları", href: "/" },
                                                //Element
                                                "Tüm Ürün Grupları"
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:2 },
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-auto justify-content-end align-items-center p-0 m-0 q14r3p" },
                                        //Element
                                        React.createElement( "button", { className: "float-start w-100 p-0 m-0 rounded-circle f4i977", type: "button", onClick: this.OD4M51 },
                                            //Element
                                            "X"
                                        )
                                    )
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( "li", { onClick: this.J979H4 },
                    //Element
                    React.createElement( "a", { className: "d-flex w-auto flex-column position-relative text-uppercase", title: "Çözümler", href: "/cozumler" },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1" },
                            //Element
                            "Çözümler"
                        ),
                        //Element
                        React.createElement( "strong", { className: "float-start w-auto p-0 m-0 fw-normal" },
                            //Element
                            "Fikir Edin"
                        )
                    )
                ),
                //Element
                React.createElement( "li", { onClick: this.J979H4 },
                    //Element
                    React.createElement( "a", { className: "d-flex w-auto flex-column position-relative text-uppercase", title: "Esinlen", href: "/esinlen" },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1" },
                            //Element
                            "Esinlen"
                        ),
                        //Element
                        React.createElement( "strong", { className: "float-start w-auto p-0 m-0 fw-normal" },
                            //Element
                            "İlham Al"
                        )
                    )
                ),
                //Element
                React.createElement( "li", { onClick: this.J979H4 },
                    //Element
                    React.createElement( "a", { className: "d-flex w-auto flex-column position-relative text-uppercase", title: "Keşfedin", href: "/kesfedin" },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1" },
                            //Element
                            "Keşfet"
                        ),
                        //Element
                        React.createElement( "strong", { className: "float-start w-auto p-0 m-0 fw-normal" },
                            //Element
                            "Eklentiler"
                        )
                    )
                ),
                //Element
                React.createElement( "li", { onClick: this.J979H4 },
                    //Element
                    React.createElement( "a", { className: "d-flex w-auto flex-column position-relative text-uppercase", title: "Daha Fazlası", href: "/daha-fazla" },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1" },
                            //Element
                            "Daha Fazlası"
                        ),
                        //Element
                        React.createElement( "strong", { className: "float-start w-auto p-0 m-0 fw-normal" },
                            //Element
                            "Araçlar ve Kaynaklar"
                        )
                    )
                ),
                //Element
                React.createElement( "li", { onClick: this.J979H4 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 zw822k" },
                        //Element
                        React.createElement( "button", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 me-2 bg-transparent border-0 c3y66m", type: "button" },
                            //Element
                            React.createElement( Z92H5B, {} )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;