//Install
import React, { Component } from "react";
//Install
import { ReactComponent as AO147M } from "../../../Media/Icon/002.svg";
//Install
import propTypes from "prop-types";

//Element
class Tool extends Component {
    //Element
    render() {
        const { ID, Title, Category, Summary, Media, Target } = this.props
        //Element
        let Source = "https://cdn.benozgurtosun.com/003/"
        //Element
        let Icon   = Source + Media
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-4 mb-4 position-relative f6hb66", href: "/" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 o45712" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute start-0 top-0 z8d261" },
                    //Element
                    React.createElement( "img", { alt: Title, title: Title, src: Icon, width: "32", height: "32" } )
                ),
                //Element
                React.createElement( "div", { className: "d-flex w-auto flex-column justify-content-center align-items-start m4b47m" },
                    //Element
                    React.createElement( "p", { className: "d-flex w-100 justify-content-between p-0 m-0 x4l8n2" },
                        //Element
                        Category
                    ),
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 c4372c" },
                        //Element
                        Title
                    ),
                    //Element
                    React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-truncate sil914" },
                        //Element
                        Summary
                    )
                ),
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 me-3 position-absolute end-0 top-0 y6657a" },
                    //Element 
                    React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle b0b9i2" },
                        //Element
                        React.createElement( AO147M, {} )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Tool;