//Install
import React, { Component } from "react";
//Install
import { ReactComponent as K34G92 } from "../../../Media/Icon/002.svg";
//Install
import propTypes from "prop-types";

//Element
class Square extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Media, Target } = this.props
        //Element
        let Source = "https://cdn.benozgurtosun.com/003/"
        //Element
        let Image  = Source + Media
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-5 hk262a", href: Target },
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-2 o205yi" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Image, width: "64", height: "64" } )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-1 b7m29b" },
                //Element
                React.createElement( "h6", { className: "d-flex w-100 justify-content-start p-0 m-0 mt-4 f8h94b" },
                    //Element
                    Title,
                    //Element
                    React.createElement( K34G92, {} )
                ),
                //Element
                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-1 d-none ju871e" },
                    //Element
                    Summary
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Square.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Media   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Square;