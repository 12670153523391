//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import D00K7H from "../../../Media/Image/003.png";
//Install
import propTypes from "prop-types";

//Element
class Horizontal extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Media, Target } = this.props
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-4 mb-2 f2125v", href: "/" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:7 },
                        //Element
                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 o5v5y1" },
                            //Element
                            React.createElement( "img", { alt: Title, title: Title, src: D00K7H, width: "636", height: "auto" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:5 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-start p-0 ps-4 pe-4 m-0 a5m09p" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 a2ke76" },
                                //Element
                                "Fermentum"
                            ),
                            //Element
                            React.createElement( "h3", { className: "float-start w-100 p-0 m-0 mt-2 w2ny94" },
                                //Element
                                Title
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 a2ke76" },
                                //Element
                                Summary
                            ),
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center v4w8c6" },
                                //Element
                                "Hemen Bakın"
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Horizontal.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Media   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Horizontal;