//Install
import React, { Component } from "react";
//Install
import Filter from "../Tab/Document/Filter";

//Element
class End extends Component {
    //Element
    R2K489 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let AF775I = document.querySelectorAll( ".a5516u li a" )
        //Element
        for (let Index = 0; Index < AF775I.length; Index++) {
            //Element
            AF775I[Index].classList.remove( "f510ov" )
        }
        //Element
        e.target.classList.add( "f510ov" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 a5516u" },
            //Element
            React.createElement( "ul", { className: "float-start w-auto p-0 m-0 ij7797" },
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 ps-3 pe-3 pb-3 m-0 position-relative f510ov", href: "/", onClick: this.R2K489 },
                        //Element
                        "Kaynaklar"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 ps-3 pe-3 pb-3 m-0 ms-4 position-relative", href: "/", onClick: this.R2K489 },
                        //Element
                        "Belgeler"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 ps-3 pe-3 pb-3 m-0 ms-4 position-relative", href: "/", onClick: this.R2K489 },
                        //Element
                        "Broşürler"
                    )
                )
            ),
            //Element
            React.createElement( Filter, {} )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default End;