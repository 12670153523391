//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Product from "../../Shortcode/Card/Product";
//Install
import { ReactComponent as W93SP8 } from "../../../Media/Icon/033.svg";
//Install
import { ReactComponent as LN759M } from "../../../Media/Icon/049.svg";
//Install
import Featured from "../../../Media/Image/006.png";
//Install
import propTypes from "prop-types";

//Element
class Private extends Component {
    //Element
    HI607A = (e) => {
        //Element
        e.preventDefault()
        //Element
        let H0QX30 = document.getElementsByClassName( "k69jr3" )[0]
        //Element
        let P6071Q = document.getElementsByClassName( "xs002z" )[0]
        //Element
        let Q92K35 = document.getElementsByClassName( "c9w8b5" )[0]
        //Element
        let Z7856J = document.getElementsByClassName( "hvm661" )[0]
        //Element
        H0QX30.classList.toggle( "d-none" )
        //Element
        P6071Q.classList.toggle( "v2gx07" )
        //Element
        Q92K35.classList.toggle( "p24663" )
        //Element
        Z7856J.classList.toggle( "d-none" )
    }
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Image, Target } = this.props
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 y986qm", title: Title, href: Target },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative b34t0q" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 m8pz02" },
                                //Element
                                React.createElement( "img", { alt: Title, title: Title, src: Featured, width: "636", height: "636" } )
                            ),
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center p-0 m-0 position-absolute start-0 top-0 t2i742" },
                                //Element
                                React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative rounded-circle c9w8b5", onClick: this.HI607A },
                                    //Element
                                    React.createElement( "i", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle ts0t33" },
                                        //Element
                                        React.createElement( LN759M, {} )
                                    ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 position-absolute xs002z" } ),
                                    //Element
                                    React.createElement( Product, {} )
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-start align-items-start p-0 m-0 position-absolute b53c51" },
                                //Element
                                React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative rounded-circle jq690c", onClick: this.HI607A },
                                    //Element
                                    React.createElement( "i", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle iy714q" },
                                        //Element
                                        React.createElement( LN759M, {} )
                                    ),
                                    //Element
                                    React.createElement( Product, {} )
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-start align-items-start p-0 m-0 position-absolute m5v46p" },
                                //Element
                                React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative rounded-circle s3745c", onClick: this.HI607A },
                                    //Element
                                    React.createElement( "i", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle v434vj" },
                                        //Element
                                        React.createElement( LN759M, {} )
                                    ),
                                    //Element
                                    React.createElement( Product, {} )
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-start align-items-start p-0 m-0 position-absolute g13m29" },
                                //Element
                                React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative rounded-circle h7793f", onClick: this.HI607A },
                                    //Element
                                    React.createElement( "i", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle c822lx" },
                                        //Element
                                        React.createElement( LN759M, {} )
                                    ),
                                    //Element
                                    React.createElement( Product, {} )
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-start align-items-start p-0 m-0 position-absolute cb499q" },
                                //Element
                                React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative rounded-circle o2m7o0", onClick: this.HI607A },
                                    //Element
                                    React.createElement( "i", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle o8a514" },
                                        //Element
                                        React.createElement( LN759M, {} )
                                    ),
                                    //Element
                                    React.createElement( Product, {} )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-start p-0 m-0 hvm661" },
                            //Element
                            React.createElement( "h1", { className: "float-start w-auto p-0 m-0 di4354" },
                                //Element
                                "Tüm Hava Koşullarına Karşı Üstün Koruma"
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 ozw032" },
                                //Element
                                "Parapet sistemleri çeşitli projelerde hem temel güvenliği sağlayarak hem de mimari estetiği arttırarak önemli bir rol oynamaktadır."
                            ),
                            //Element
                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 ozw032" },
                                //Element
                                "İster ticari mülklerde, ister konut yapılarında, ister kamusal alanlarda uygulansın, bu sistemler koruma ve tasarım iyileştirmenin bir karışımını sunar."
                            ),
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 m8880y" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-4 text-center b37816" },
                                    //Element
                                    "Hemen Bakın"
                                ),
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-4 ms-3 text-center bn600f" },
                                    //Element
                                    "Nerede Kullanılır?"
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Private.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Image   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Private;