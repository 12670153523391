//Install
import React, { Component } from "react";
//Install
import { ReactComponent as W435KH } from "../../../Media/Icon/042.svg";
//Install
import { ReactComponent as E2871U } from "../../../Media/Icon/043.svg";
//Install
import { ReactComponent as L90YU5 } from "../../../Media/Icon/044.svg";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 d854to" },
            //Element
            React.createElement( "ul", { className: "float-start w-50 p-0 m-0 t8246s" },
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 ps-3 pe-3 pb-3 m-0 position-relative b0627q", href: "/" },
                        //Element
                        "Ürün Grupları"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 ps-3 pe-3 pb-3 m-0 ms-4 position-relative", href: "/" },
                        //Element
                        "Uygulama Türleri"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 ps-3 pe-3 pb-3 m-0 ms-4 position-relative", href: "/" },
                        //Element
                        "Kullanım Alanları"
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;