//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import { ReactComponent as SY1311 } from "../../../Media/Icon/003.svg";
//Install
import { ReactComponent as C0N704 } from "../../../Media/Icon/048.svg";
//Install
import Image from "./Image";

//Element
class Default extends Component {
    //Element
    L807QJ = (e) => {
        //Element
        e.preventDefault()
        //Element
        let P0916Z = document.getElementsByClassName( "s090z9" )[0]
        //Element
        P0916Z.classList.toggle( "d-none" )
    }
    //Element
    render() {
        //Element
        const Y41B87 = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_V118MC",
                //Element
                prevEl      : "#Web_X36EF6"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-fixed start-0 top-0 d-none s090z9" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:1 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 fbp356" },
                            //Element
                            React.createElement( "button", { id: "Web_X36EF6", name: "Web_X36EF6", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative rounded-circle j695eq" },
                                //Element
                                React.createElement( SY1311, {} )
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:10 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative t636aw" },
                            //Element
                            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Y41B87 },
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "004.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "001.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "002.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "003.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "005.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "006.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "007.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "008.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "009.jpg", Target: "/" } )
                                )
                            ),
                            //Element
                            React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center bg-white border-0 position-absolute p-0 m-0 c80u2d", onClick: this.L807QJ },
                                //Element
                                React.createElement( C0N704, {} )
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:1 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 fbp356" },
                            //Element
                            React.createElement( "button", { id: "Web_V118MC", name: "Web_V118MC", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative rounded-circle h700yx" },
                                //Element
                                React.createElement( SY1311, {} )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;