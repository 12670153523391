//Install
import React, { Component } from "react";
//Install
import { ReactComponent as L8869M } from "../../../../Media/Icon/003.svg";

//Element
class Campaign extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-4 mb-2 cg4d09" },
            //Element
            React.createElement( "button", { id: "Web_D7QR13", name: "Web_D7QR13", className: "d-flex w-100 justify-content-center align-items-center rounded-circle position-relative v20u8h", type: "button" },
                //Element
                React.createElement( L8869M, {} )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 mt-4 ms-3 me-3 z9865p" },

            ),
            //Element
            React.createElement( "button", { id: "Web_MR7360", name: "Web_MR7360", className: "float-start w-100 rounded-circle position-relative u09ed7", type: "button" },
                //Element
                React.createElement( L8869M, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Campaign;