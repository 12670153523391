//Install
import React, { Component } from "react";
//Install
import { ReactComponent as K34G92 } from "../../../Media/Icon/002.svg";
//Install
import propTypes from "prop-types";

//Element
class Medium extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Category, Data, Media, Target } = this.props
        //Element
        let Source = "https://cdn.benozgurtosun.com/001/"
        //Element
        let Image  = Source + Media
        //Element
        const Content = React.createElement( "a", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 pb-5 m-0 o7o75g", href: Target },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 p575al" },
                //Element
                React.createElement( "label", { className: "float-start w-100 p-3 ps-5 m-0 bg-white lh-1 m9576x" },
                    //Element
                    Category
                )
            ),
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 overflow-hidden ntu526" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Image, width: "315", height: "auto" } )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 ps-5 pe-5 m-0 mt-4 mb-1 p575al" },
                //Element
                React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-1 a061nt" },
                    //Element
                    Data
                ),
                //Element
                React.createElement( "h4", { className: "d-flex w-100 justify-content-between p-0 m-0 pr6232" },
                    //Element
                    Title,
                    //Element
                    React.createElement( K34G92, {} )
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 a061nt" },
                    //Element
                    Summary
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Medium.propTypes = {
    ID       : propTypes.string.isRequired,
    Title    : propTypes.string.isRequired,
    Summary  : propTypes.string.isRequired,
    Category : propTypes.string.isRequired,
    Data     : propTypes.string.isRequired,
    Media    : propTypes.string.isRequired,
    Target   : propTypes.string.isRequired
}
//Element
export default Medium;