//Install
import React, { Component } from "react";
//Install
import { ReactComponent as L8869M } from "../../../../Media/Icon/003.svg";

//Element
class Photo extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 uy177r" },
            //Element
            React.createElement( "button", { id: "Web_DA686F", name: "Web_DA686F", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative jw3m79", type: "button" },
                //Element
                React.createElement( L8869M, {} )
            ),
            //Element
            React.createElement( "button", { id: "Web_U049NP", name: "Web_U049NP", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative a3x70i", type: "button" },
                //Element
                React.createElement( L8869M, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Photo;