//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Image from "../../Shortcode/Card/Image";
//Install
import Nav from "../../Shortcode/Navigation/Slider/Photo";
//Install
import { ReactComponent as L8869M } from "../../../Media/Icon/003.svg";

//Element
class First extends Component {
    //Element
    render() {
        //Element
        const XM90M6 = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_SY05B0",
                //Element
                prevEl      : "#Web_H2368A"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-5 m-0 mt-4 a627mg j0p36f" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:12 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...XM90M6 },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "004.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "001.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "002.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "003.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "005.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "006.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "007.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "008.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "009.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 uy177r" },
                            //Element
                            React.createElement( "button", { id: "Web_H2368A", name: "Web_H2368A", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative jw3m79", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            ),
                            //Element
                            React.createElement( "button", { id: "Web_SY05B0", name: "Web_SY05B0", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative a3x70i", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "9"
                            )
                        )
                    )
                ),
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default First;