//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Product extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 d78164" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:12 },
                    //Element
                    React.createElement( "ul", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-1 q2z36x" },
                        //Element
                        React.createElement( "li", { className: "float-start w-auto" },
                            //Element
                            React.createElement( "a", { className: "d-flex w-auto flex-column justify-content-start align-items-center d8340a", href: "/" },
                                //Element
                                "Tamamını Görüntüle"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-auto" },
                            //Element
                            React.createElement( "a", { className: "d-flex w-auto flex-column justify-content-start align-items-center", href: "/" },
                                //Element
                                "Öne Çıkanlar"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-auto" },
                            //Element
                            React.createElement( "a", { className: "d-flex w-auto flex-column justify-content-start align-items-center", href: "/" },
                                //Element
                                "Yeni Ürünler"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-auto" },
                            //Element
                            React.createElement( "a", { className: "d-flex w-auto flex-column justify-content-start align-items-center", href: "/" },
                                //Element
                                "Trend Ürünler"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-auto" },
                            //Element
                            React.createElement( "a", { className: "d-flex w-auto flex-column justify-content-start align-items-center", href: "/" },
                                //Element
                                "İndirim'de Olanlar"
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Product;