//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Nav from "./Footer/Navigation";
//Install
import Menu from "./Footer/Menu";
//Install
import Slide from "./Footer/Slide";
//Install
import Social from "./Footer/Social";
//Install
import Gallery from "./Shortcode/Gallery/Default";
//Install
import Copyright from "./Footer/Copyright";
//Install
import Newsletter from "./Footer/Newsletter";

//Element
class Footer extends Component {
    //Element
    render() {
        //Element
        const Slider = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_D7062T",
                //Element
                prevEl      : "#Web_Z474XF"
            },
            //Element
            autoplay        : {
                //Element
                delay       : 5000
            },
            //Element
            loop            : true,
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 t2g1l7" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 m656h5" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:9 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-auto justify-content-start align-items-center p-0 m-0 zh015e" },
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 o0si34" },
                                    //Element
                                    "Bülten"
                                ),
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden q4nw34", ...Slider },
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Slide, { ID: "0", Title: "Muud ve Tivibu GO’da Mobil Ödeme ile Harca, Kullanım Bedeli Ödeme", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Slide, { ID: "0", Title: "Muud ve Tivibu GO’da Mobil Ödeme ile Harca, Kullanım Bedeli Ödeme", Target: "/" } )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Nav, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 zn1079" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:9 },
                            //Element
                            React.createElement( Menu, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Newsletter, {} ),
                            //Element
                            React.createElement( Social, { ID: "0", Facebook: "/", X: "/", Instagram: "/", Youtube: "/", Linkedin: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ub1s65" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 s083be" },
                                //Element
                                React.createElement( Copyright, { ID: "0", Title: "Copyright © 2024 SYZ Şirketler Grubu Tüm Hakları Saklıdır.", Target: "/" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Gallery, {} )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Footer;