//Install
import React, { Component } from "react";
//Install
import { ReactComponent as ZC4080 } from "../../../Media/Icon/051.svg";
//Install
import { ReactComponent as BPX629 } from "../../../Media/Icon/052.svg";
//Install
import propTypes from "prop-types";

//Element
class Blog extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Media, Target } = this.props
        //Element
        let Source = "https://cdn.benozgurtosun.com/001/"
        //Element
        let Image  = Source + Media
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-4 k984bm", href: Target },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 x51kl8" },
                //Element
                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 mb-3 overflow-hidden rc7p72" },
                    //Element
                    React.createElement( "img", { alt: Title, title: Title, src: Image, width: "424", height: "auto" } )
                ),
                //Element
                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 x76p97" },
                    //Element
                    React.createElement( "p", { className: "float-start w-auto p-0 m-0 xG0j09" },
                        //Element
                        "Blog - 10 Ekim 2024"
                    ),
                    //Element
                    React.createElement( "h6", { className: "float-start w-auto p-0 m-0 mt-1 qr19v0" },
                        //Element
                        Title
                    ),
                    //Element
                    React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-1 h000ts" },
                        //Element
                        Summary
                    ),
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Blog.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Media   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Blog;