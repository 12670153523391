//Install
import React, { Component } from "react";
//Install
import { ReactComponent as W435KH } from "../../../Media/Icon/042.svg";
//Install
import { ReactComponent as E2871U } from "../../../Media/Icon/043.svg";
//Install
import { ReactComponent as L90YU5 } from "../../../Media/Icon/044.svg";

//Element
class Discover extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 d854to" },
            //Element
            React.createElement( "ul", { className: "float-start w-auto p-0 m-0 t8246s" },
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 ps-3 pe-3 pb-3 m-0 position-relative b0627q", href: "/" },
                        //Element
                        "Keşfedin"
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-end w-50 p-0 m-0 d78164" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-end p-0 m-0 pgi752" },
                    //Element
                    React.createElement( "button", { id: "Web_X004KE", name: "Web_X004KE", className: "float-start w-auto p-0 m-0 bg-transparent border-0 e568ib", type: "button", onClick: this.J9749M },
                        //Element
                        React.createElement( W435KH, {} )
                    ),
                    //Element
                    React.createElement( "button", { id: "Web_X58G2D", name: "Web_X58G2D", className: "float-start w-auto p-0 m-0 ms-3 bg-transparent border-0 e568ib", type: "button", onClick: this.PK4968 },
                        //Element
                        React.createElement( E2871U, {} )
                    ),
                    //Element
                    React.createElement( "button", { id: "Web_N066Z8", name: "Web_N066Z8", className: "float-start w-auto p-0 m-0 ms-3 bg-transparent border-0 e568ib", type: "button", onClick: this.NU6773 },
                        //Element
                        React.createElement( L90YU5, {} )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Discover;