//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import VJ286Y from "./Content/Slider/Start";
//Install
import W1D57J from "./Content/Slider/Top";
//Install
import T7973S from "./Content/Slider/End";
//Install
import M92C19 from "./Content/Slider/Bottom";
//Install
import N0F52S from "./Content/Slider/Private";
//Install
import O135PM from "./Content/Slider/Square";
//Install
import S875PV from "./Content/Slider/Medium";
//Install
import US7I24 from "./Content/Slider/Small";
//Install
import I7560E from "./Content/Slider/Blog";
//Install
import P45282 from "./Content/Group/Default";
//Install
import VI072L from "./Content/Group/Product";
//Install
import HY545P from "./Content/Group/Discover";
//Install
import M250SP from "./Content/Filter/Default";
//Install
import R132EZ from "./Content/Filter/Product";
//Install
import L3S321 from "./Content/Button/Tool";
//Install
import N052F4 from "./Content/Navigation/Right";
//Install
import O8J4J4 from "./Shortcode/Navigation/Slider/Campaign";
//Install
import I94HY7 from "./Content/Slider/Horizontal";
//Install
import T5276E from "./Content/Navigation/Top";
//Install
import T0W37S from "./Shortcode/Card/Square";
//Install
import D058V0 from "./Content/Slider/Vertical";
//Install
import Solution from "./Content/Navigation/Start";
//Install
import Tab from "./Content/Sub/Solution";
//Install
import Filter from "./Content/Tab/Filter";
//Install
import First from "./Content/Gallery/First";
//Install
import Fourth from "./Content/Gallery/Fourth";
//Install
import Three from "./Content/Gallery/Three";
//Install
import Guide from "./Content/Button/Guide";
//Install
import Resource from "./Content/Navigation/End";
//Install
import Instagram from "./Shortcode/Card/Instagram";
//Install
import GJ1687 from "../Component/Shortcode/Navigation/Content/Document";
//Install
import { ReactComponent as W9Y223 } from "../Media/Icon/053.svg";
//Install
import { ReactComponent as G3863H } from "../Media/Icon/054.svg";
//Install
import { ReactComponent as AO147M } from "../Media/Icon/002.svg";
//Install
import A562O2 from "../Media/Image/018.png";
//Install
import W966W1 from "../Media/Image/003.png";

//Element
class Main extends Component {
    //Element
    C3718J = (e) => {
        //Element
        e.preventDefault()
        //Element
        let C444O5 = document.querySelectorAll( ".n35g84 li a" )
        //Element
        let J1FC22 = document.querySelectorAll( ".w87r7n" )
        //Element
        for (let Index = 0; Index < C444O5.length; Index++) {
            //Element
            C444O5[Index].classList.remove( "w87r7n" )
        }
        //Element
        console.log( e.nativeEvent.target.offsetParent.classList.toggle( "w87r7n" ) )
    }
    //Element
    Q045Y3 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let C444O5 = document.querySelector( ".n35g84 li :first-child .iy400h" )
        //Element
        C444O5.classList.toggle( "d-none" )
    }
    //Element
    AN687K = (e) => {
        //Element
        e.preventDefault()
        //Element
        let AF775I = document.querySelectorAll( ".p1r0k5 li a" )
        //Element
        for (let Index = 0; Index < AF775I.length; Index++) {
            //Element
            AF775I[Index].classList.remove( "d8340a" )
        }
        //Element
        e.target.classList.add( "d8340a" )
    }
    //Element
    P481AS = (e) => {
        //Element
        e.preventDefault()
        //Element
        let AF775I = document.querySelectorAll( ".t8246s li a" )
        //Element
        for (let Index = 0; Index < AF775I.length; Index++) {
            //Element
            AF775I[Index].classList.remove( "b0627q" )
        }
        //Element
        e.target.classList.add( "b0627q" )
    }
    //Element
    N201R6 = () => {
        //Element
        let X213GP = document.getElementById( "Sticky_Header" )
        //Element
        let A061J5 = X213GP.offsetTop
        //Element
        if( window.pageYOffset > A061J5 ){
            //Element
            X213GP.classList.add( "d3348h" )
            //Element
            X213GP.classList.remove( "d-none" )
        //Element
        } else {
            //Element
            X213GP.classList.add( "d-none" )
        }
    }
    //Element
    componentDidMount(){
        //Element
        window.addEventListener( "scroll", this.N201R6 )
        //Element
        let ZQ2T47 = document.getElementById( "Web_ZQ2T47" )
        //Element
        let B323HG = document.getElementById( "Web_P2T66Z" )
        //Element
        let C444O5 = document.querySelectorAll( ".n35g84 li:first-child a" )
        //Element
        ZQ2T47.classList.add( "d-none" )
        //Element
        B323HG.classList.add( "d-none" )
        //Element
        for (let Index = 0; Index < C444O5.length; Index++) {
            //Element
            C444O5[Index].classList.add( "w87r7n" )
        }
    }
    //Element
    render() {
        //Element
        const L840U1 = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_W7U51A",
                //Element
                prevEl      : "#Web_S1110M"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const W57J7G = {
            //Element
            spaceBetween    : "24",
            //Element
            slidesPerView   : "6",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_T53GR5",
                //Element
                prevEl      : "#Web_IG035Z"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 7
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const G227AV = {
            //Element
            spaceBetween    : "24",
            //Element
            slidesPerView   : "3",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_T53GR5",
                //Element
                prevEl      : "#Web_IG035Z"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 3
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const H3861V = {
            //Element
            spaceBetween    : "24",
            //Element
            slidesPerView   : "3",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_T53GR5",
                //Element
                prevEl      : "#Web_IG035Z"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 3
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const T1568O = {
            //Element
            spaceBetween    : "24",
            //Element
            slidesPerView   : "3",
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 3
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const CR7534 = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_MR7360",
                //Element
                prevEl      : "#Web_D7QR13"
            },
            //Element
            pagination      : {
                //Element
                el          : ".z9865p",
                //Element
                clickable   : true,
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const X9C19A = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v3p702" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w86037" },
                //Element
                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...L840U1 },
                    //Element
                    React.createElement( SwiperSlide, {},
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative cf5716" },
                            //Element
                            React.createElement( N0F52S, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Tüm Hava Koşullarına Karşı Üstün Bir Koruma Sunar.", Image: "", Target: "/" } )
                        )
                    ),
                    //Element
                    React.createElement( SwiperSlide, {},
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative cf5716" },
                            //Element
                            React.createElement( W1D57J, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Tüm Hava Koşullarına Karşı Üstün Bir Koruma Sunar.", Image: "", Target: "/" } )
                        )
                    ),
                    //Element
                    React.createElement( SwiperSlide, {},
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative cf5716" },
                            //Element
                            React.createElement( T7973S, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Tüm Hava Koşullarına Karşı Üstün Bir Koruma Sunar.", Image: "", Target: "/" } )
                        )
                    ),
                    //Element
                    React.createElement( SwiperSlide, {},
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative cf5716" },
                            //Element
                            React.createElement( M92C19, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Tüm Hava Koşullarına Karşı Üstün Bir Koruma Sunar.", Image: "", Target: "/" } )
                        )
                    ),
                    //Element
                    React.createElement( SwiperSlide, {},
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative cf5716" },
                            //Element
                            React.createElement( VJ286Y, { ID: "0", Title: "Tüm Hava Koşullarına Karşı Üstün Koruma", Summary: "Tüm Hava Koşullarına Karşı Üstün Bir Koruma Sunar.", Image: "", Target: "/" } )
                        )
                    )
                )
            ),
             //Element
             React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 p4130q" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-5 o53963" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 t1587z" },
                                    //Element
                                    React.createElement( "h2", { className: "float-start w-auto p-0 m-0 z8kc66" },
                                        //Element
                                        "Fermentum Commodo Fermentum"
                                    ),
                                    //Element
                                    React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-1 j4t1g5" },
                                        //Element
                                        "Fermentum Commodo Congue Fermentum Commodo Congue. Fermentum Commodo Congue."
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 g3v1k0" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( P45282, {} ),
                            //Element
                            React.createElement( M250SP, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 x29hh9" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...W57J7G },
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( O135PM, { ID: "0", Title: "Fermentum Commodo", Summary: "Hemen Bakın", Media: "055.svg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( O135PM, { ID: "0", Title: "Fermentum Commodo", Summary: "Hemen Bakın", Media: "056.svg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( O135PM, { ID: "0", Title: "Fermentum Commodo", Summary: "Hemen Bakın", Media: "057.svg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( O135PM, { ID: "0", Title: "Fermentum Commodo", Summary: "Hemen Bakın", Media: "058.svg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( O135PM, { ID: "0", Title: "Fermentum Commodo", Summary: "Hemen Bakın", Media: "059.svg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( O135PM, { ID: "0", Title: "Fermentum Commodo", Summary: "Hemen Bakın", Media: "060.svg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( O135PM, { ID: "0", Title: "Fermentum Commodo", Summary: "Hemen Bakın", Media: "061.svg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( O135PM, { ID: "0", Title: "Fermentum Commodo", Summary: "Hemen Bakın", Media: "062.svg", Target: "/" } )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 g3v1k0" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( VI072L, {} ),
                            //Element
                            React.createElement( R132EZ, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pb-4 m-0 i4466s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 g7s757" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...G227AV },
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( S875PV, { ID: "0", Title: "Fermentum Congue Fermentum Congue", Summary: "Fermentum Commodo Congue Fermentum Commodo Fermentum Commodo Congue.", Media: "021.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( S875PV, { ID: "0", Title: "Fermentum Congue Fermentum Congue", Summary: "Fermentum Commodo Congue Fermentum Commodo Fermentum Commodo Congue.", Media: "022.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( S875PV, { ID: "0", Title: "Fermentum Congue Fermentum Congue", Summary: "Fermentum Commodo Congue Fermentum Commodo Fermentum Commodo Congue.", Media: "023.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( S875PV, { ID: "0", Title: "Fermentum Congue Fermentum Congue", Summary: "Fermentum Commodo Congue Fermentum Commodo Fermentum Commodo Congue.", Media: "024.jpg", Target: "/" } )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 y631q5" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 yc5j10" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 f9b148" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( L3S321, { ID: "0", Title: "Fermentum Commodo", Category: "Fermentum", Summary: "Fermentum Commodo Fermentum Commodo.", Media: "063.svg", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( L3S321, { ID: "0", Title: "Fermentum Commodo", Category: "Fermentum", Summary: "Fermentum Commodo Fermentum Commodo.", Media: "064.svg", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( L3S321, { ID: "0", Title: "Fermentum Commodo", Category: "Fermentum", Summary: "Fermentum Commodo Fermentum Commodo.", Media: "065.svg", Target: "/" } )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 g3v1k0" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( HY545P, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 i4466s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 g7s757" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...H3861V },
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( US7I24, { ID: "0", Title: "Fermentum Congue Fermentum Congue", Summary: "Fermentum Congue Commodo Fermentum Congue Fermentum Congue Commodo.", Category: "Fermentum", Data: "Ürünler", Media: "021.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( US7I24, { ID: "0", Title: "Fermentum Congue Fermentum Congue", Summary: "Fermentum Congue Commodo Fermentum Congue Fermentum Congue Commodo.", Category: "Fermentum", Data: "Ürünler", Media: "022.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( US7I24, { ID: "0", Title: "Fermentum Congue Fermentum Congue", Summary: "Fermentum Congue Commodo Fermentum Congue Fermentum Congue Commodo.", Category: "Fermentum", Data: "Ürünler", Media: "023.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( US7I24, { ID: "0", Title: "Fermentum Congue Fermentum Congue", Summary: "Fermentum Congue Commodo Fermentum Congue Fermentum Congue Commodo.", Category: "Fermentum", Data: "Ürünler", Media: "024.jpg", Target: "/" } )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t105li" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:7 },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 position-relative vj4d67" },
                                //Element
                                React.createElement( "img", { className: "float-start w-100 h-auto", src: A562O2, width: "636", height: "auto" } ),
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-center align-items-center p-0 m-0 position-absolute q480hd" },
                                    //Element
                                    React.createElement( "button", { className: "float-start w-100 p-0 m-0 rounded-circle f0024m", type: "button", onClick: this.Q045Y3 },
                                        //Element
                                        "1"
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md: "4 offset-md-1" },
                            //Element
                            React.createElement( "ul", { className: "float-start w-100 p-0 pe-3 m-0 mt-5 n35g84" },
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 mb-1 position-relative f90w3h", href: "/", onClick: this.C3718J },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 pe-2 m-0 pk366q" },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w1438b" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-auto position-relative b048nm" },
                                                    //Element
                                                    "Fermentum Commodo Fermentum"
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 aa311n" },
                                                    //Element
                                                    React.createElement( W9Y223, {} )
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 d-none g09892" },
                                                    //Element
                                                    React.createElement( G3863H, {} )
                                                )
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 iy400h" },
                                                //Element
                                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 pav138" },
                                                    //Element
                                                    React.createElement( "img", { alt: "Liman Kafe", title: "Liman Kafe", className: "float-start w-100 h-auto", src: "https://cdn.benozgurtosun.com/001/005.jpg", width: "318", height: "auto" } )
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 mb-1 position-relative f90w3h", href: "/", onClick: this.C3718J },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 pe-2 m-0 pk366q" },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w1438b" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-auto position-relative b048nm" },
                                                    //Element
                                                    "Fermentum Commodo Fermentum"
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 aa311n" },
                                                    //Element
                                                    React.createElement( W9Y223, {} )
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 d-none g09892" },
                                                    //Element
                                                    React.createElement( G3863H, {} )
                                                )
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 iy400h" },
                                                //Element
                                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 pav138" },
                                                    //Element
                                                    React.createElement( "img", { alt: "Liman Kafe", title: "Liman Kafe", className: "float-start w-100 h-auto", src: "https://cdn.benozgurtosun.com/001/005.jpg", width: "318", height: "auto" } )
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 mb-1 position-relative f90w3h", href: "/", onClick: this.C3718J },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 pe-2 m-0 pk366q" },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w1438b" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-auto position-relative b048nm" },
                                                    //Element
                                                    "Fermentum Commodo Fermentum"
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 aa311n" },
                                                    //Element
                                                    React.createElement( W9Y223, {} )
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 d-none g09892" },
                                                    //Element
                                                    React.createElement( G3863H, {} )
                                                )
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 iy400h" },
                                                //Element
                                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 pav138" },
                                                    //Element
                                                    React.createElement( "img", { alt: "Liman Kafe", title: "Liman Kafe", className: "float-start w-100 h-auto", src: "https://cdn.benozgurtosun.com/001/005.jpg", width: "318", height: "auto" } )
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 mb-1 position-relative f90w3h", href: "/", onClick: this.C3718J },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 pe-2 m-0 pk366q" },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w1438b" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-auto position-relative b048nm" },
                                                    //Element
                                                    "Fermentum Commodo Fermentum"
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 aa311n" },
                                                    //Element
                                                    React.createElement( W9Y223, {} )
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 d-none g09892" },
                                                    //Element
                                                    React.createElement( G3863H, {} )
                                                )
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 iy400h" },
                                                //Element
                                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 pav138" },
                                                    //Element
                                                    React.createElement( "img", { alt: "Liman Kafe", title: "Liman Kafe", className: "float-start w-100 h-auto", src: "https://cdn.benozgurtosun.com/001/005.jpg", width: "318", height: "auto" } )
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 mb-1 position-relative f90w3h", href: "/", onClick: this.C3718J },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 pe-2 m-0 pk366q" },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w1438b" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-auto position-relative b048nm" },
                                                    //Element
                                                    "Fermentum Commodo Fermentum"
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 aa311n" },
                                                    //Element
                                                    React.createElement( W9Y223, {} )
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 d-none g09892" },
                                                    //Element
                                                    React.createElement( G3863H, {} )
                                                )
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 iy400h" },
                                                //Element
                                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 pav138" },
                                                    //Element
                                                    React.createElement( "img", { alt: "Liman Kafe", title: "Liman Kafe", className: "float-start w-100 h-auto", src: "https://cdn.benozgurtosun.com/001/005.jpg", width: "318", height: "auto" } )
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 mb-1 position-relative f90w3h", href: "/", onClick: this.C3718J },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 pe-2 m-0 pk366q" },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w1438b" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-auto position-relative b048nm" },
                                                    //Element
                                                    "Fermentum Commodo Fermentum"
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 aa311n" },
                                                    //Element
                                                    React.createElement( W9Y223, {} )
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 d-none g09892" },
                                                    //Element
                                                    React.createElement( G3863H, {} )
                                                )
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 iy400h" },
                                                //Element
                                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 pav138" },
                                                    //Element
                                                    React.createElement( "img", { alt: "Liman Kafe", title: "Liman Kafe", className: "float-start w-100 h-auto", src: "https://cdn.benozgurtosun.com/001/005.jpg", width: "318", height: "auto" } )
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 mb-1 position-relative f90w3h", href: "/", onClick: this.C3718J },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 pe-2 m-0 pk366q" },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w1438b" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-auto position-relative b048nm" },
                                                    //Element
                                                    "Fermentum Commodo Fermentum"
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 aa311n" },
                                                    //Element
                                                    React.createElement( W9Y223, {} )
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 d-none g09892" },
                                                    //Element
                                                    React.createElement( G3863H, {} )
                                                )
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 iy400h" },
                                                //Element
                                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 pav138" },
                                                    //Element
                                                    React.createElement( "img", { alt: "Liman Kafe", title: "Liman Kafe", className: "float-start w-100 h-auto", src: "https://cdn.benozgurtosun.com/001/005.jpg", width: "318", height: "auto" } )
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 mb-1 position-relative f90w3h", href: "/", onClick: this.C3718J },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 pe-2 m-0 pk366q" },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w1438b" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-auto position-relative b048nm" },
                                                    //Element
                                                    "Fermentum Commodo Fermentum"
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 aa311n" },
                                                    //Element
                                                    React.createElement( W9Y223, {} )
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 d-none g09892" },
                                                    //Element
                                                    React.createElement( G3863H, {} )
                                                )
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 iy400h" },
                                                //Element
                                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 pav138" },
                                                    //Element
                                                    React.createElement( "img", { alt: "Liman Kafe", title: "Liman Kafe", className: "float-start w-100 h-auto", src: "https://cdn.benozgurtosun.com/001/005.jpg", width: "318", height: "auto" } )
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 mb-1 position-relative f90w3h", href: "/", onClick: this.C3718J },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 pe-2 m-0 pk366q" },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w1438b" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-auto position-relative b048nm" },
                                                    //Element
                                                    "Fermentum Commodo Fermentum"
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 aa311n" },
                                                    //Element
                                                    React.createElement( W9Y223, {} )
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 d-none g09892" },
                                                    //Element
                                                    React.createElement( G3863H, {} )
                                                )
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 iy400h" },
                                                //Element
                                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 pav138" },
                                                    //Element
                                                    React.createElement( "img", { alt: "Liman Kafe", title: "Liman Kafe", className: "float-start w-100 h-auto", src: "https://cdn.benozgurtosun.com/001/005.jpg", width: "318", height: "auto" } )
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 mb-1 position-relative f90w3h", href: "/", onClick: this.C3718J },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 pe-2 m-0 pk366q" },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w1438b" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-auto position-relative b048nm" },
                                                    //Element
                                                    "Fermentum Commodo Fermentum"
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 aa311n" },
                                                    //Element
                                                    React.createElement( W9Y223, {} )
                                                ),
                                                //Element
                                                React.createElement( "i", { className: "float-end w-auto p-0 d-none g09892" },
                                                    //Element
                                                    React.createElement( G3863H, {} )
                                                )
                                            ),
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 iy400h" },
                                                //Element
                                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 pav138" },
                                                    //Element
                                                    React.createElement( "img", { alt: "Liman Kafe", title: "Liman Kafe", className: "float-start w-100 h-auto", src: "https://cdn.benozgurtosun.com/001/005.jpg", width: "318", height: "auto" } )
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 ac97l1" },
                                                    //Element
                                                    "Fermentum Commodo Donec Fermentum Commodo Donec. Fermentum Commodo Donec."
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pb-5 m-0 mt-5 r56620" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-5 position-relative t57508", href: "/" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0" },
                                            //Element
                                            React.createElement( "video", { className: "float-start w-100 h-auto", src: "https://assets.tiger-coatings.com/assets/colorselector/europe/products/009-20020/009-20020.mp4", playsInline: true, autoPlay: true, loop: true, controls: false, muted: true } )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-start p-0 m-0 t57508" },
                                            //Element
                                            React.createElement( "label", { className: "float-start w-auto p-0 m-0 q5835a" },
                                                //Element
                                                "Fermentum"
                                            ),
                                            //Element
                                            React.createElement( "h6", { className: "float-start w-auto p-0 m-0 mt-2 bd4447" },
                                                //Element
                                                "Fermentum Fusce Commodo Fermentum Fusce."
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 q5835a" },
                                                //Element
                                                "Fermentum Fusce Fermentum Fusce Commodo. Fermentum Fusce Fermentum."
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:2 },
                                        //Element
                                        React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-end align-items-center p-0 m-0 me-3 position-absolute end-0 top-0 y6657a" },
                                            //Element 
                                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle b0b9i2" },
                                                //Element
                                                React.createElement( AO147M, {} )
                                            )
                                        )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-5 position-relative t57508", href: "/" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0" },
                                            //Element
                                            React.createElement( "video", { className: "float-start w-100 h-auto", src: "https://assets.tiger-coatings.com/assets/colorselector/europe/products/009-20020/009-20020.mp4", playsInline: true, autoPlay: true, loop: true, controls: false, muted: true } )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-start p-0 m-0 t57508" },
                                            //Element
                                            React.createElement( "label", { className: "float-start w-auto p-0 m-0 q5835a" },
                                                //Element
                                                "Fermentum"
                                            ),
                                            //Element
                                            React.createElement( "h6", { className: "float-start w-auto p-0 m-0 mt-2 bd4447" },
                                                //Element
                                                "Fermentum Fusce Commodo Fermentum Fusce."
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 q5835a" },
                                                //Element
                                                "Fermentum Fusce Fermentum Fusce Commodo. Fermentum Fusce Fermentum."
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:2 },
                                        //Element
                                        React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-end align-items-center p-0 m-0 me-3 position-absolute end-0 top-0 y6657a" },
                                            //Element 
                                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle b0b9i2" },
                                                //Element
                                                React.createElement( AO147M, {} )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 g3v1k0" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 d854to" },
                                //Element
                                React.createElement( "ul", { className: "float-start w-100 p-0 m-0 t8246s" },
                                    //Element
                                    React.createElement( "li", { className: "float-start w-auto" },
                                        //Element
                                        React.createElement( "a", { className: "float-start w-auto p-0 ps-3 pe-3 pb-3 m-0 position-relative b0627q", href: "/" },
                                            //Element
                                            "Blog"
                                        )
                                    )
                                )
                            ),
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 bn6596" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...T1568O },
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( I7560E, { ID: "0", Title: "Fermentum Commodo Congue Fermentum Commodo Congue.", Summary: "Fermentum Commodo Commodo Fermentum Commodo Congue? Fermentum Commodo Commodo.", Media: "005.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( I7560E, { ID: "0", Title: "Fermentum Commodo Congue Fermentum Commodo Congue.", Summary: "Fermentum Commodo Commodo Fermentum Commodo Congue? Fermentum Commodo Commodo.", Media: "006.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( I7560E, { ID: "0", Title: "Fermentum Commodo Congue Fermentum Commodo Congue.", Summary: "Fermentum Commodo Commodo Fermentum Commodo Congue? Fermentum Commodo Commodo.", Media: "007.jpg", Target: "/" } )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 y631q5" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 xl0325" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 text-center i6e7k4" },
                                    //Element
                                    "Kampanyalar"
                                )
                            ),
                            //Element
                            React.createElement( N052F4, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pb-4 m-0 position-relative s7s2p8" },
                //Element
                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...CR7534 },
                    //Element
                    React.createElement( SwiperSlide, {},
                        //Element
                        React.createElement( I94HY7, { ID: "0", Title: "Fermentum Commodo Congue Fermentum Commodo Congue.", Summary: "Fermentum Commodo Commodo Fermentum Commodo Commodo Congue Fermentum Commodo Commodo Fermentum.", Media: "", Target: "/" } )
                    ),
                    //Element
                    React.createElement( SwiperSlide, {},
                        //Element
                        React.createElement( I94HY7, { ID: "0", Title: "Fermentum Commodo Congue Fermentum Commodo Congue.", Summary: "Fermentum Commodo Commodo Fermentum Commodo Commodo Congue Fermentum Commodo Commodo Fermentum.", Media: "", Target: "/" } )
                    ),
                    //Element
                    React.createElement( SwiperSlide, {},
                        //Element
                        React.createElement( I94HY7, { ID: "0", Title: "Fermentum Commodo Congue Fermentum Commodo Congue.", Summary: "Fermentum Commodo Commodo Fermentum Commodo Commodo Congue Fermentum Commodo Commodo Fermentum.", Media: "", Target: "/" } )
                    )
                ),
                //Element
                React.createElement( O8J4J4, {} )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 y631q5" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 xl0325" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 text-center i6e7k4" },
                                    //Element
                                    "Bülten"
                                )
                            ),
                            //Element
                            React.createElement( T5276E, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pb-4 m-0 position-relative s7s2p8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Row, {},
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0" },
                                        //Element
                                        React.createElement( T0W37S, { ID: "0", Title: "Liman Kafe", Summary: "Paranızı Garanti BBVA değerlendirin.", Media: "", Target: "/" } ),
                                        //Element
                                        React.createElement( T0W37S, { ID: "0", Title: "Katar", Summary: "Paranızı Garanti BBVA değerlendirin.", Media: "", Target: "/" } ),
                                        //Element
                                        React.createElement( T0W37S, { ID: "0", Title: "Veranda", Summary: "Paranızı Garanti BBVA değerlendirin.", Media: "", Target: "/" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:8 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s7s2p8" },
                                        //Element
                                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...X9C19A },
                                            //Element
                                            React.createElement( SwiperSlide, {},
                                                //Element
                                                React.createElement( D058V0, { ID: "0", Title: "Fermentum Commodo Congue Fermentum Commodo.", Summary: "Fermentum Commodo Congue.", Media: "", Target: "/" } )
                                            ),
                                            //Element
                                            React.createElement( SwiperSlide, {},
                                                //Element
                                                React.createElement( D058V0, { ID: "0", Title: "Fermentum Commodo Congue Fermentum Commodo.", Summary: "Fermentum Commodo Congue.", Media: "", Target: "/" } )
                                            ),
                                            //Element
                                            React.createElement( SwiperSlide, {},
                                                //Element
                                                React.createElement( D058V0, { ID: "0", Title: "Fermentum Commodo Congue Fermentum Commodo.", Summary: "Fermentum Commodo Congue.", Media: "", Target: "/" } )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 g3v1k0" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Solution, {} ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 d78164" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( Tab, {} )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( Filter, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 q54as5" },
                                //Element
                                React.createElement( "div", { id: "Gallery_Three_Column", className: "float-start w-100 p-0 m-0 a627mg" },
                                    //Element
                                    React.createElement( Three, {} )
                                ),
                                //Element
                                React.createElement( "div", { id: "Gallery_Fourth_Column", className: "float-start w-100 p-0 m-0 d-none a627mg" },
                                    //Element
                                    React.createElement( Fourth, {} )
                                ),
                                //Element
                                React.createElement( "div", { id: "Gallery_First_Column", className: "float-start w-100 p-0 m-0 d-none a627mg" },
                                    //Element
                                    React.createElement( First, {} )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( GJ1687, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 mt-5 y631q5" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Resource, {} ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 yc5j10" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 s8r2d0" },
                                            //Element
                                            React.createElement( "h5", { className: "float-start w-100 p-0 m-0 text-center i15n6c" },
                                                //Element
                                                "Sertifikalar"
                                            )
                                        ),
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 f9b148" },
                                            //Element
                                            React.createElement( Guide, { ID: "1", Title: "CE", Category: "Sertifikalar", Summary: "Aradığın ürünü buradan kolayca bulabilirsin.", Target: "/" } ),
                                            //Element
                                            React.createElement( Guide, { ID: "2", Title: "Uygunluk Beyanı", Category: "Sertifikalar", Summary: "Aradığın ürünü buradan kolayca bulabilirsin.", Target: "/" } ),
                                            //Element
                                            React.createElement( Guide, { ID: "3", Title: "Bakım Klavuzu", Category: "Sertifikalar", Summary: "Aradığın ürünü buradan kolayca bulabilirsin.", Target: "/" } )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 dm933a" },
                                            //Element
                                            React.createElement( "h5", { className: "float-start w-100 p-0 m-0 text-center e36j17" },
                                                //Element
                                                "Belgeler"
                                            )
                                        ),
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 an58y3" },
                                            //Element
                                            React.createElement( Guide, { ID: "4", Title: "ISO 9001", Category: "Belgeler", Summary: "Aradığın ürünü buradan kolayca bulabilirsin.", Target: "/" } ),
                                            //Element
                                            React.createElement( Guide, { ID: "5", Title: "Teknik Şartname", Category: "Belgeler", Summary: "Aradığın ürünü buradan kolayca bulabilirsin.", Target: "/" } ),
                                            //Element
                                            React.createElement( Guide, { ID: "6", Title: "Kullanım Kılavuzu", Category: "Belgeler", Summary: "Aradığın ürünü buradan kolayca bulabilirsin.", Target: "/" } )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 s7p33r" },
                                            //Element
                                            React.createElement( "h5", { className: "float-start w-100 p-0 m-0 text-center y4340h" },
                                                //Element
                                                "Kılavuzlar"
                                            )
                                        ),
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 vc6862" },
                                            //Element
                                            React.createElement( Guide, { ID: "7", Title: "Sertifika", Category: "Kılavuzlar", Summary: "Aradığın ürünü buradan kolayca bulabilirsin.", Target: "/" } ),
                                            //Element
                                            React.createElement( Guide, { ID: "8", Title: "Testler", Category: "Kılavuzlar", Summary: "Aradığın ürünü buradan kolayca bulabilirsin.", Target: "/" } )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:12 },
                                        //Element
                                        React.createElement( GJ1687, {} )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 g3v1k0" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 d78164" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 d854to" },
                                    //Element
                                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 xh0f44" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-auto" },
                                            //Element
                                            React.createElement( "a", { className: "float-start w-auto p-0 ps-3 pe-3 pb-3 m-0 position-relative text-capitalize b0627q", href: "/" },
                                                //Element
                                                "Instagram'da Takip Edin"
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 qz291v" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Instagram, { ID: "0", Title: "SYZ Grup", Media: "portrait-smiling-friends-standing-beach_1048944-28658596.jpg", Target: "https://www.instagram.com" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Instagram, { ID: "0", Title: "SYZ Grup", Media: "portrait-cute-baby-girl-lying-home_1048944-16380304.jpg", Target: "https://www.instagram.com" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Instagram, { ID: "0", Title: "SYZ Grup", Media: "medium-shot-family-retro-style_52683-115524.jpg", Target: "https://www.instagram.com" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Instagram, { ID: "0", Title: "SYZ Grup", Media: "beautiful-lesbian-couple-celebrating-their-wedding-day-outdoors_23-2150637608.jpg", Target: "https://www.instagram.com" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Instagram, { ID: "0", Title: "SYZ Grup", Media: "side-view-woman-holding-hands_1048944-16242081.jpg", Target: "https://www.instagram.com" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Instagram, { ID: "0", Title: "SYZ Grup", Media: "psychological-therapy-support-group-meeting_23-2151044467.jpg", Target: "https://www.instagram.com" } )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Main;