//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Image from "../../Shortcode/Card/Image";
//Install
import Nav from "../../Shortcode/Navigation/Slider/Photo";
//Install
import { ReactComponent as L8869M } from "../../../Media/Icon/003.svg";

//Element
class Three extends Component {
    //Element
    L807QJ = (e) => {
        //Element
        e.preventDefault()
        //Element
        let P0916Z = document.getElementsByClassName( "s090z9" )[0]
        //Element
        P0916Z.classList.toggle( "d-none" )
    }
    //Element
    render() {
        //Element
        const K895LD = {
            //Element
            spaceBetween    : "24",
            //Element
            slidesPerView   : "3",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_W0EC36",
                //Element
                prevEl      : "#Web_Z812IF"
            },
            //Element
            freeMode        : true,
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const RA7178 = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_OM455D",
                //Element
                prevEl      : "#Web_YM2714"
            },
            //Element
            pagination      : {
                //Element
                el          : ".bs668d",
                //Element
                clickable   : true
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const I4I18R = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_OM455D",
                //Element
                prevEl      : "#Web_YM2714"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 a627mg" },
            //Element
            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...K895LD },
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...RA7178 },
                            //Element
                            React.createElement( SwiperSlide, { onClick: this.L807QJ },
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "004.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, { onClick: this.L807QJ },
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "001.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, { onClick: this.L807QJ },
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "002.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, { onClick: this.L807QJ },
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "003.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, { onClick: this.L807QJ },
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "005.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, { onClick: this.L807QJ },
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "006.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, { onClick: this.L807QJ },
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "007.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, { onClick: this.L807QJ },
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "008.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, { onClick: this.L807QJ },
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "009.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 uy177r" },
                            //Element
                            React.createElement( "button", { id: "Web_YM2714", name: "Web_YM2714", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative jw3m79", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            ),
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-end p-0 m-0 mt-4 mb-4 position-absolute start-0 bottom-0 bs668d" } ),
                            //Element
                            React.createElement( "button", { id: "Web_OM455D", name: "Web_OM455D", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative a3x70i", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "9"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...I4I18R },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "005.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "1"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...I4I18R },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "006.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "1"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...I4I18R },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "001.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "1"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...K895LD },
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...I4I18R },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "003.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "1"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...I4I18R },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "002.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "1"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...I4I18R },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "001.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "1"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...I4I18R },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "005.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "1"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...K895LD },
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...I4I18R },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "007.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "1"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...I4I18R },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "008.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "1"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...I4I18R },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "009.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "1"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( SwiperSlide, {},
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...I4I18R },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "006.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "1"
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Three;