//Install
import React, { Component } from "react";
//Install
import { ReactComponent as AO147M } from "../../../Media/Icon/002.svg";
//Install
import YE000A from "../../../Media/Image/014.png";
//Install
import R57F8I from "../../../Media/Image/015.png";
//Install
import propTypes from "prop-types";

//Element
class Guide extends Component {
    //Element
    render() {
        const { ID, Title, Category, Summary, Media, Target } = this.props
        //Element
        let Icon
        //Element
        if( ID == "1" ){
            //Element
            Icon = React.createElement( "img", { alt: "CE", title: "CE", src: YE000A, width: "64", height: "64" } )
        } else if( ID == "4" ){
            //Element
            Icon = React.createElement( "img", { alt: "CE", title: "CE", src: R57F8I, width: "64", height: "64" } )
        }
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-4 position-relative f6hb66", href: "/" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 o45712" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute start-0 top-0 z8d261" },
                    //Element
                    Icon
                ),
                //Element
                React.createElement( "div", { className: "d-flex w-auto flex-column justify-content-center align-items-start m4b47m" },
                    //Element
                    React.createElement( "p", { className: "d-flex w-100 justify-content-between p-0 m-0 x4l8n2" },
                        //Element
                        Category,
                        //Element
                        React.createElement( "strong", { className: "me-1" },
                            //Element
                            "3"
                        )
                    ),
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 c4372c" },
                        //Element
                        Title
                    ),
                    //Element
                    React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-truncate sil914" },
                        //Element
                        Summary
                    )
                ),
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 me-3 position-absolute end-0 top-0 y6657a" },
                    //Element 
                    React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle b0b9i2" },
                        //Element
                        React.createElement( AO147M, {} )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Guide.propTypes = {
    ID       : propTypes.string.isRequired,
    Title    : propTypes.string.isRequired,
    Category : propTypes.string.isRequired,
    Summary  : propTypes.string.isRequired,
    Media    : propTypes.string.isRequired,
    Target   : propTypes.string.isRequired
}
//Element
export default Guide;