//Install
import React, { Component } from "react";
//Install
import D00K7H from "../../../Media/Image/001.jpg";
//Install
import propTypes from "prop-types";

//Element
class Vertical extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Media, Target } = this.props
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-4 mb-2 position-relative c44l62", href: "/" },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 o5v5y1", style: { backgroundImage: "url("+ D00K7H +")" } } ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 ps-5 pe-5 m-0 position-absolute start-0 top-0 t846a0" },
                //Element
                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 c3t837" },
                    //Element
                    "Fermentum"
                ),
                //Element
                React.createElement( "h3", { className: "float-start w-100 p-0 m-0 mt-1 ma9764" },
                    //Element
                    Title
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 c3t837" },
                    //Element
                    Summary
                ),
                //Element
                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-2 text-center d-none i398kx" },
                    //Element
                    "Hemen Bakın"
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Vertical.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Media   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Vertical;