//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Z3UI31 } from "../../../Media/Icon/002.svg";
//Install
import { ReactComponent as P62492 } from "../../../Media/Icon/003.svg";
//Install
import { ReactComponent as W435KH } from "../../../Media/Icon/042.svg";
//Install
import { ReactComponent as E2871U } from "../../../Media/Icon/043.svg";
//Install
import { ReactComponent as L90YU5 } from "../../../Media/Icon/044.svg";
//Install
import { ReactComponent as Q2003G } from "../../../Media/Icon/045.svg";

//Element
class Filter extends Component {
    //Element
    J9749M = (e) => {
        //Element
        e.preventDefault()
        //Element
        let L5A0V1 = document.getElementById( "Gallery_Three_Column" )
        //Element
        let Z992JN = document.getElementById( "Gallery_Fourth_Column" )
        //Element
        let D467O7 = document.getElementById( "Gallery_First_Column" )
        //Element
        L5A0V1.classList.add( "d-none" )
        //Element
        Z992JN.classList.add( "d-none" )
        //Element
        D467O7.classList.add( "d-none" )
        //Element
        L5A0V1.classList.remove( "d-none" )
    }
    //Element
    PK4968 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let L5A0V1 = document.getElementById( "Gallery_Three_Column" )
        //Element
        let Z992JN = document.getElementById( "Gallery_Fourth_Column" )
        //Element
        let D467O7 = document.getElementById( "Gallery_First_Column" )
        //Element
        L5A0V1.classList.add( "d-none" )
        //Element
        Z992JN.classList.add( "d-none" )
        //Element
        D467O7.classList.add( "d-none" )
        //Element
        Z992JN.classList.remove( "d-none" )
    }
    //Element
    NU6773 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let L5A0V1 = document.getElementById( "Gallery_Three_Column" )
        //Element
        let Z992JN = document.getElementById( "Gallery_Fourth_Column" )
        //Element
        let D467O7 = document.getElementById( "Gallery_First_Column" )
        //Element
        L5A0V1.classList.add( "d-none" )
        //Element
        Z992JN.classList.add( "d-none" )
        //Element
        D467O7.classList.add( "d-none" )
        //Element
        D467O7.classList.remove( "d-none" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-end p-0 m-0 pgi752" },
            //Element
            React.createElement( "button", { className: "float-start w-auto p-0 m-0 bg-transparent border-0 e568ib", type: "button", onClick: this.J9749M },
                //Element
                React.createElement( W435KH, {} )
            ),
            //Element
            React.createElement( "button", { className: "float-start w-auto p-0 m-0 ms-2 bg-transparent border-0 e568ib", type: "button", onClick: this.PK4968 },
                //Element
                React.createElement( E2871U, {} )
            ),
            //Element
            React.createElement( "button", { className: "float-start w-auto p-0 m-0 ms-2 bg-transparent border-0 e568ib", type: "button", onClick: this.NU6773 },
                //Element
                React.createElement( L90YU5, {} )
            ),
            //Element
            React.createElement( "button", { id: "Web_Z812IF", name: "Web_Z812IF", className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 ms-2 bg-transparent border-0 position-relative x78h80", type: "button" },
                //Element
                React.createElement( P62492, {} )
            ),
            //Element
            React.createElement( "button", { id: "Web_W0EC36", name: "Web_W0EC36", className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 ms-2 bg-transparent border-0 position-relative c9k30d", type: "button" },
                //Element
                React.createElement( P62492, {} )
            ),
            //Element
            React.createElement( "button", { className: "float-start w-auto p-0 m-0 ms-2 bg-transparent border-0 d-none e568ib", type: "button" },
                //Element
                React.createElement( Q2003G, {} )
            ),
            //Element
            React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-3 text-uppercase xn000f", href: "/" },
                //Element
                "Esin Kaynağı",
                //Element
                React.createElement( Z3UI31, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Filter;