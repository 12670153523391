//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Image from "../../Shortcode/Card/Image";
//Install
import Nav from "../../Shortcode/Navigation/Slider/Photo";
//Install
import { ReactComponent as L8869M } from "../../../Media/Icon/003.svg";

//Element
class Fourth extends Component {
    //Element
    render() {
        //Element
        const W741JB = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_U049NP",
                //Element
                prevEl      : "#Web_DA686F"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const W9955X = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_L44796",
                //Element
                prevEl      : "#Web_P8VL45"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const U90B0B = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_V083JF",
                //Element
                prevEl      : "#Web_C27SU2"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const T1GW09 = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_MRJ090",
                //Element
                prevEl      : "#Web_AC360H"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const BV5Y49 = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_K5310Q",
                //Element
                prevEl      : "#Web_S9F379"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const V40C36 = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_A309DH",
                //Element
                prevEl      : "#Web_KZ9042"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const WN5076 = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_A309DH",
                //Element
                prevEl      : "#Web_KZ9042"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const U55T3C = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_N5N798",
                //Element
                prevEl      : "#Web_M8AI70"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const A863QB = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_VJJ554",
                //Element
                prevEl      : "#Web_C8992A"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 a627mg" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...W741JB },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "004.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "001.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "002.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "003.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "005.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "006.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "007.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "008.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "009.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( Nav, {} ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "9"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...W9955X },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "005.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "001.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "002.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "003.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "004.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "006.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "007.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "008.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "009.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 uy177r" },
                            //Element
                            React.createElement( "button", { id: "Web_P8VL45", name: "Web_P8VL45", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative jw3m79", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            ),
                            //Element
                            React.createElement( "button", { id: "Web_L44796", name: "Web_L44796", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative a3x70i", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "9"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...U90B0B },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "006.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "001.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "002.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "003.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "004.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "005.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "007.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "008.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "009.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 uy177r" },
                            //Element
                            React.createElement( "button", { id: "Web_C27SU2", name: "Web_C27SU2", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative jw3m79", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            ),
                            //Element
                            React.createElement( "button", { id: "Web_V083JF", name: "Web_V083JF", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative a3x70i", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "9"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...T1GW09 },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "003.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "001.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "002.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "004.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "005.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "006.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "007.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "008.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "009.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 uy177r" },
                            //Element
                            React.createElement( "button", { id: "Web_AC360H", name: "Web_AC360H", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative jw3m79", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            ),
                            //Element
                            React.createElement( "button", { id: "Web_MRJ090", name: "Web_MRJ090", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative a3x70i", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "9"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...BV5Y49 },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "002.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "001.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "003.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "004.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "005.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "006.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "007.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "008.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "009.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 uy177r" },
                            //Element
                            React.createElement( "button", { id: "Web_S9F379", name: "Web_S9F379", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative jw3m79", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            ),
                            //Element
                            React.createElement( "button", { id: "Web_K5310Q", name: "Web_K5310Q", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative a3x70i", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "9"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...V40C36 },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "001.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "002.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "003.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "004.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "005.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "006.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "007.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "008.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "009.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 uy177r" },
                            //Element
                            React.createElement( "button", { id: "Web_KZ9042", name: "Web_KZ9042", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative jw3m79", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            ),
                            //Element
                            React.createElement( "button", { id: "Web_A309DH", name: "Web_A309DH", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative a3x70i", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "9"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...U55T3C },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "007.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "001.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "002.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "003.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "004.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "005.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "006.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "008.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "009.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 uy177r" },
                            //Element
                            React.createElement( "button", { id: "Web_M8AI70", name: "Web_M8AI70", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative jw3m79", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            ),
                            //Element
                            React.createElement( "button", { id: "Web_N5N798", name: "Web_N5N798", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative a3x70i", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "9"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s5b6d6" },
                        //Element
                        React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...A863QB },
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "008.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "001.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "002.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "003.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "004.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "005.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "006.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "007.jpg", Target: "/" } )
                            ),
                            //Element
                            React.createElement( SwiperSlide, {},
                                //Element
                                React.createElement( Image, { ID: "0", Title: "SYZ Group", Media: "009.jpg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 uy177r" },
                            //Element
                            React.createElement( "button", { id: "Web_C8992A", name: "Web_C8992A", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative jw3m79", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            ),
                            //Element
                            React.createElement( "button", { id: "Web_VJJ554", name: "Web_VJJ554", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative a3x70i", type: "button" },
                                //Element
                                React.createElement( L8869M, {} )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-4 position-absolute end-0 top-0 p2g042" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center m9674q" },
                                //Element
                                "9"
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Fourth;