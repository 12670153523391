//Install
import React, { Component } from "react";
//Install
import { ReactComponent as FE1968 } from "../../../Media/Icon/050.svg";
//Install
import { ReactComponent as K34G92 } from "../../../Media/Icon/002.svg";
//Install
import propTypes from "prop-types";

//Element
class Medium extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Media, Target } = this.props
        //Element
        let Source = "https://cdn.benozgurtosun.com/001/"
        //Element
        let Image  = Source + Media
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-4 ti82s2", href: Target },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 overflow-hidden y76166" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Image, width: "315", height: "auto" } ),
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center position-absolute rounded-circle e3d07k" },
                    //Element
                    React.createElement( FE1968, {} )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-5 pt-4 pb-4 m-0 q548av" },
                //Element
                React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-1 h11e3p" },
                    //Element
                    "Ürünler"
                ),
                //Element
                React.createElement( "h4", { className: "d-flex w-100 justify-content-between p-0 m-0 mh2q68" },
                    //Element
                    Title,
                    //Element
                    React.createElement( K34G92, {} )
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 w731gz" },
                    //Element
                    Summary
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Medium.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Media   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Medium;